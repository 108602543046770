<template>
  <v-sheet
    class="calendar-module main-listing-page px-3"
    style="height: calc(100vh - 88px); background: #f5f5f5"
  >
    <div class="calendar-wrapper">
      <div class="calendar-area">
        <v-sheet>
          <v-toolbar height="44" flat>
            <v-btn
              depressed
              tile
              color="blue darken-4"
              class="white--text"
              @click="setToday"
            >
              Today
            </v-btn>
            <v-btn
              fab
              outlined
              height="28"
              width="28"
              class="ml-3"
              color="grey darken-2"
              @click="prev"
            >
              <v-icon> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn
              fab
              outlined
              height="28"
              width="28"
              class="ml-3"
              color="grey darken-2"
              @click="next"
            >
              <v-icon> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar" class="ml-3">
              <span style="font-size: 16px" class="fw-600">
                {{ $refs.calendar.title }}
              </span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-flex class="d-flex justify-content-end align-items-center">
              <div
                class="mx-5 font-level-3-bold d-inline-block primary--text text--darken-4 fw-600"
                style=""
              >
                Filters
              </div>
              <v-flex class="mr-2" style="max-width: 15rem">
                <DatePicker
                  outlined
                  depressed
                  hide-details
                  placeholder="Select Date"
                  v-on:click:clear="getCalendar()"
                  class-name="mt-0"
                  clearable
                  v-on:change="getCalendar($event)"
                >
                </DatePicker>
              </v-flex>
              <!-- <v-flex class="mr-2" style="max-width: 14rem">
                <v-autocomplete
                  :items="statusList"
                  v-model="status"
                  hide-details
                  clearable
                  flat
                  solo
                  dense
                  label="Status"
                  color="cyan"
                  item-color="cyan"
                  class="pt-0 mt-0"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-action class="mr-0">
                      <v-chip
                        style="height: 16px !important; width: 16px; padding: 0"
                        :color="item.color"
                      >
                      </v-chip>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                        >{{ item.text }}</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action
                      class="align-self-center"
                      v-if="status == item.status"
                    >
                      <v-icon color="cyan">mdi-check</v-icon>
                    </v-list-item-action>
                  </template>
                </v-autocomplete>
              </v-flex> -->
              <v-flex class="mr-2" style="max-width: 15rem">
                <v-autocomplete
                  :items="filterList"
                  v-model="filter"
                  hide-details
                  clearable
                  flat
                  solo
                  dense
                  label="Type"
                  color="cyan"
                  item-color="cyan"
                  v-on:change="getCalendar()"
                  class="pt-0 mt-0"
                >
                  <template v-slot:item="{ item }">
                    <!-- <v-list-item-action class="mr-0">
                      <v-chip
                        style="height: 26px !important; width: 26px; padding: 0"
                        :color="item.color"
                        class="d-flex justify-center align-center"
                      >
                        <span style="font-size: 16px">
                          {{ item.count }}
                        </span>
                      </v-chip>
                    </v-list-item-action> -->
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                        >{{ item.text }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-flex>
            </v-flex>
            <v-menu v-if="false" left tile deppressed>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  tile
                  outlined
                  color="blue darken-4"
                  class="mr-3"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span>{{ calendarFilterType }}</span>
                  <v-icon right style="font-size: 24px"> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list class="">
                <v-list-item
                  class="border-bottom"
                  @click="calendarFilterType = 'Assigned Visit'"
                >
                  <v-list-item-title class="fw-600"
                    >Assigned Visit</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="calendarFilterType = 'UnAssigned Visit'">
                  <v-list-item-title class="fw-600"
                    >UnAssigned Visit</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu left tile deppressed>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  tile
                  color="blue darken-4"
                  class="white--text"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list class="py-0">
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 days</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              depressed
              tile
              color="blue darken-4"
              class="white--text ml-3"
              :to="{ name: 'admin.calendar-resource' }"
            >
              Resource Calendar
            </v-btn>
          </v-toolbar>
        </v-sheet>
        <div class="calendar pl-2" style="height: calc(100vh - 140px)">
          <!--  <pre>{{ events }}</pre> -->
          <v-calendar
            ref="calendar"
            v-model="focus"
            style="width: 100%"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            @click:event="openSingledata"
            @click:more="showAllEvent"
            @click:date="viewDay"
            @change="updateRange"
          >
            <template v-slot:event="{ event }">
              <template v-if="event.type != 'visit'">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-if="event?.has_tool == 1" v-bind="attrs" v-on="on" color="green darken-4" small class="ml-1">mdi-tools</v-icon>
                  </template>
                  <span>Tools</span>
                </v-tooltip>
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      small
                      v-bind="attrs"
                      v-on="on"
                      style="width: 97%"
                      :color="event.color"
                      class="ml-1 mb-2"
                      label
                      outlined
                    >
                      <span
                        class="font-size-10 font-weight-500 text-capitalize"
                      >
                        {{ getEvents(event) }}
                      </span>
                    </v-chip>
                  </template>
                  <span> {{ event.status_text }}</span>
                </v-tooltip>
                <!-- <span style="color:black"> 
                     
                  </span> -->
              </template>
              <div v-else class="d-flex">
                <div>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-if="event?.has_tool == 1" v-bind="attrs" v-on="on" color="green darken-4" small class="ml-1">mdi-tools</v-icon>
                    </template>
                    <span>Tools</span>
                  </v-tooltip>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        small
                        v-bind="attrs"
                        v-on="on"
                        :color="event.priority_color"
                        class="ml-1 text-white mt-n1"
                        label
                        style="width: 10px !important"
                      >
                      </v-chip>
                    </template>
                    <span>Priority</span>
                  </v-tooltip>
                </div>
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      small
                      v-bind="attrs"
                      v-on="on"
                      style="width: 97%"
                      :color="event.color"
                      class="ml-1 text-white"
                      label
                    >
                      <span>
                        <div></div>
                      </span>
                      <span
                        class="font-size-10 font-weight-500 text-capitalize"
                      >
                        {{ getEvents(event) }}
                      </span>
                    </v-chip>
                  </template>
                  <span> {{ event.status_text }}</span>
                </v-tooltip>
              </div>

              <!-- <v-chip
               
                small
                style="width: 97%;"
                :color="event.color"
                label
                class="ml-1 text-white"
              >
                <span class="font-size-16 font-weight-500 text-capitalize">
                  {{ event.name }}
                </span>
              </v-chip> -->
            </template>
          </v-calendar>
          <!--   <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-y
            top
            left
            min-width="auto"
          >
            <v-card
              color=""
              min-width="300px"
              max-width="350px"
              elevation="0"
              class="all-event-wrapper"
            >
              <v-toolbar
                :color="selectedEvent.color"
                dark
                height="40px"
                class=""
                flat
              >
                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                <v-spacer></v-spacer>
              
                <v-icon
                  class="cursor-pointer ml-4"
                  size="18"
                  @click="selectedOpen = false"
                  >mdi-close</v-icon
                >
              </v-toolbar>
              <div class="pa-3">
                <v-card
                  min-width="245px"
                  max-width="300px"
                  flat
                  class="pa-1"
                  color="grey lighten-4"
                >
                 
                </v-card>
              </div>
            </v-card>
          </v-menu> -->
          <v-menu
            v-model="moreOpen"
            :close-on-content-click="false"
            :activator="moreElement"
            offset-y
            top
            left
          >
            <v-card
              class="grey pa-2 lighten-4"
              min-width="350px"
              max-width="350px"
              style="height: 600px"
            >
              <template v-for="(row, index) in moreData">
                <v-card-text
                  :class="`${row.color} white--text mb-1 pa-1`"
                  :key="index"
                >
                  {{ row.name }} {{ formatDate(row.start) }}
                </v-card-text>
              </template>
              <!-- <v-card-text class="blue white--text mb-1 pa-1">
                05:30 AM - Meeting
              </v-card-text>
              <v-card-text class="red white--text mb-1 pa-1">
                05:30 AM - Meeting
              </v-card-text>
              <v-card-text class="orange white--text mb-1 pa-1">
                05:30 AM - Meeting
              </v-card-text>
              <v-card-text class="blue white--text mb-1 pa-1">
                05:30 AM - Meeting
              </v-card-text> -->
            </v-card>
          </v-menu>
        </div>
      </div>
      <div class="sidebar px-3">
        <div class="position-sticky">
          <div class="d-flex py-1">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <h5
                  class="mt-2 py-0 pl-2 cursor-pointer"
                  style="border-color: #a3a3a3 !important"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ filterType }}
                  <v-icon size="28">mdi-menu-down</v-icon>
                </h5>
              </template>
              <v-list>
                <v-list-item-group color="primary">
                  <v-list-item link @click="filterType = 'All visit'">
                    <v-list-item-title
                      class="fw-600 d-flex"
                      v-on:click="getVisitList('', 'all')"
                    >
                      <!-- <span
                        class="white--text mr-2 rounded-circle green d-flex justify-center align-center"
                        style="height: 22px; width: 22px"
                        >23</span
                      > -->
                      All Visit</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link @click="filterType = 'Assigned Visit'">
                    <v-list-item-title
                      class="fw-600 d-flex"
                      v-on:click="getVisitList('', 'assigned')"
                    >
                      <!-- <span
                        class="white--text mr-2 rounded-circle green d-flex justify-center align-center"
                        style="height: 22px; width: 22px"
                        >23</span
                      > -->
                      Assigned Visit</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link @click="filterType = 'UnAssigned Visit'">
                    <v-list-item-title
                      class="fw-600 d-flex"
                      v-on:click="getVisitList('', 'ussigned')"
                    >
                      <!-- <span
                        class="white--text mr-2 rounded-circle blue d-flex justify-center align-center"
                        style="height: 22px; width: 22px"
                        >23</span
                      > -->
                      UnAssigned Visit</v-list-item-title
                    >
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <v-btn
              fab
              icon
              small
              @click="showCalendarFilter = !showCalendarFilter"
            >
              <v-icon>mdi-calendar-check-outline</v-icon>
            </v-btn>
            <!--  <v-btn fab icon small min-width="30" width="30">
              <v-icon>mdi-filter</v-icon>
            </v-btn> -->
          </div>
          <div v-if="showCalendarFilter" class="mb-2">
            <v-date-picker
              full-width
              no-title
              color="green lighten-1"
              v-model="datePicker"
              :event-color="(date) => (date[9] % 2 ? 'red' : 'yellow')"
              :events="functionEvents"
              v-on:change="getVisitList(datePicker)"
            ></v-date-picker>
          </div>
          <!--  <div class="key-search mb-2">
            <v-text-field
              class="px-0"
              dense
              filled
              solo
              @keydown.enter="getVisitList"
              v-model="search"
              flat
              placeholder="Search Visit"
              append-icon="mdi-magnify"
            ></v-text-field>
          </div> -->
        </div>
        <div class="event-list">
          <v-list class="py-0 grey lighten-4">
            <v-list-item-group v-model="selected" active-class="blue lighten-4">
              <template v-for="(item, index) in visitArr">
                <v-list-item
                  :key="index"
                  class="mb-2 white px-2 border-red"
                  :style="`border-left: ${getPriorityColor(
                    item.ticket_calendar.priority_text
                  )}; box-shadow: 0 2px 10px 0px rgba(0, 0, 0, 0.1); background-color: ${getBgColor(
                    item.status
                  )}`"
                >
                  <!--  :style="`border:1px solid ${statusObject ? statusObject.color : ''}`" -->
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title
                        :class="`blue--text mb-1`"
                        class="fw-700 d-flex align-center"
                      >
                        #{{ item.barcode }}
                        <v-spacer></v-spacer>
                        <span :class="`${getColor(item.status)}--text fw-500`">
                          <span class="d-block">
                            {{ formatDate(item.started_at) }}
                            {{ formatDateTimeStart(item.started_at) }}
                            <!-- -
                              {{ formatetimedata(item.finished_at) }} -->
                          </span>

                          <!-- {{getPriorityColor(item.priority_text)}} -->
                        </span>
                      </v-list-item-title>
                      <v-list-item-title
                        style="font-size: 16px"
                        class="fw-600"
                        :class="`${
                          active ? getColor(item.status) : ''
                        }--text mb-1`"
                        v-text="item.title"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        class="text--primary fw-500"
                        v-text="item.customer.company_name"
                      ></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <!--   <v-icon
                        size="18"
                        class=""
                        :color="`${getPriorityColor(item.ticket_calendar.priority_text)}`"
                      >
                        mdi-circle
                      </v-icon> -->
                      <v-icon
                        v-if="item.follow_up > 0"
                        color="red"
                        size="18"
                        class="my-1"
                      >
                        mdi-flag</v-icon
                      >
                      <v-icon v-else size="18" class="my-1"> mdi-flag</v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <!--   <v-list-item
                  :key="index"
                  class="green lighten-1 mb-2 px-2"
                  v-else
                >
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="white--text fw-600">{{
                      item.name
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action class="my-0 fw-700">
                    <span
                      class="red--text rounded-circle white d-flex justify-center align-center"
                      style="height: 22px; width: 22px"
                      >23</span
                    >
                  </v-list-item-action>
                </v-list-item> -->
              </template>
            </v-list-item-group>
          </v-list>
          <v-menu
            v-model="eventPopover"
            :close-on-click="false"
            :close-on-content-click="false"
            :position-x="positionX"
            :position-y="positionY"
            absolute
            offset-y
            tile
            elevation="0"
            min-width="350"
          >
            <v-card
              color=""
              min-width="300px"
              max-width="350px"
              elevation="0"
              class="all-event-wrapper"
            >
              <v-toolbar color="green" dark height="40px" class="">
                <v-toolbar-title>Lorem, ipsum dolor.</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon
                  class="cursor-pointer"
                  size="18"
                  v-on:click="openSingledata($event)"
                  >mdi-pencil</v-icon
                >
                <v-icon
                  class="cursor-pointer ml-4"
                  size="18"
                  @click="eventPopover = false"
                  >mdi-close</v-icon
                >
              </v-toolbar>
              <div class="pa-3">
                <table width="100%">
                  <tr>
                    <th>Name</th>
                    <td>Birthday</td>
                  </tr>
                  <tr>
                    <th>Start Date</th>
                    <td class="blue--text">12/12/2022 - 12:00 PM</td>
                  </tr>
                  <tr>
                    <th>End Date</th>
                    <td class="red--text">12/12/2022 - 02:00 PM</td>
                  </tr>
                  <tr>
                    <th>Participant</th>
                    <td>
                      <v-avatar color="blue lighten-3" size="30" class="mr-1">
                        <span class="blue--text">JP</span>
                      </v-avatar>
                      <v-avatar color="orange lighten-3" size="30" class="mr-1">
                        <span class="orange--text">JP</span>
                      </v-avatar>
                      <v-avatar color="green lighten-3" size="30" class="mr-1">
                        <span class="green--text">JP</span>
                      </v-avatar>
                      <v-avatar color="red lighten-3" size="30" class="mr-1">
                        <span class="red--text">JP</span>
                      </v-avatar>
                    </td>
                  </tr>
                  <tr>
                    <th valign="top">Description</th>
                    <td>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Molestiae, deleniti.
                    </td>
                  </tr>
                </table>
              </div>
            </v-card>
          </v-menu>
        </div>
      </div>
    </div>
    <CreateEvent
      v-if="false"
      :dialog="viewVisitDialog"
      v-on:close="viewVisitDialog = false"
    ></CreateEvent>

    <SingleVisitDetail
      v-if="viewVisitDialog"
      :visit-dialog="viewVisitDialog"
      :visit="visitId"
      v-on:close="viewVisitDialog = false"
    ></SingleVisitDetail>
    <SingleQuotationDetail
      v-if="viewQuotationDialog"
      :visit-dialog="viewQuotationDialog"
      :visit="quotationId"
      v-on:close="viewQuotationDialog = false"
    ></SingleQuotationDetail>
    <SingleLeaveDetail
      v-if="viewLeaveDialog"
      :visit-dialog="viewLeaveDialog"
      :visit="leaveId"
      v-on:close="viewLeaveDialog = false"
    ></SingleLeaveDetail>
    <SingleContractDetail
      v-if="viewContractDialog"
      :visit-dialog="viewContractDialog"
      :visit="contractId"
      v-on:close="viewContractDialog = false"
    ></SingleContractDetail>
  </v-sheet>
</template>
<style>
.v-calendar-weekly__day:hover {
  background-color: #d7e0e1 !important;
}
</style>
<script>
import { /* GET, */ QUERY } from "@/core/services/store/request.module";
import CreateEvent from "@/view/pages/calendar/CreateEvent";
import DatePicker from "@/view/components/DatePicker";
import moment from "moment-timezone";
import SingleQuotationDetail from "@/view/pages/job/partials/Single-Quotation-Detail.vue";
import SingleContractDetail from "@/view/pages/job/partials/Single-Contract-Detail.vue";
import SingleLeaveDetail from "@/view/pages/job/partials/Single-Leave-Detail.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import SingleVisitDetail from "@/view/pages/job/partials/Single-Visit-Detail.vue";
import { toSafeInteger } from "lodash";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  name: "Calendar",
  title: "Calendar",
  mixins: [CommonMixin],
  data() {
    return {
      filterType: "All visit",
      calendarFilterType: "Assigned Visit",
      showCalendarFilter: false,
      pageTitle: "Calender",
      search: null,
      moreData: [],
      viewVisitDialog: false,
      viewContractDialog: false,
      viewQuotationDialog: false,
      viewLeaveDialog: false,
      datePicker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      selected: false,
      eventPopover: false,
      positionX: 0,
      positionY: 0,
      eventspopup: null,

      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      moreElement: null,
      moreOpen: false,
      visitId: 0,
      contractId: 0,
      quotationId: 0,
      leaveId: 0,
      status: 5,
      events: [],
      settingsData: [],
      visitArr: [],
      colors: [
        "red lighten-1",
        "green lighten-1",
        "blue lighten-1",
        "orange lighten-1",
        "pink lighten-1",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
      arrayEvents: null,
      statusList: [
        {
          type: 12,
          text: "All Visits",
          value: "all",
          description: "All Visits",
          activity_text: "All Visits",
          color: "cyan",
          textcolor: "white",
          headercolor: "primary",
          order: 1,
          status: 0,
          top_visible: 1,
        },
        {
          type: 12,
          text: "Open",
          value: "open",
          description: "Open Visits",
          activity_text: "Open",
          color: "blue darken-1",
          textcolor: "white",
          headercolor: "primary",
          order: 2,
          status: 1,
          top_visible: 1,
        },
        {
          type: 12,
          text: "In-Progress",
          value: "in-progress",
          description: "In-Progress Visits",
          activity_text: "In-Progress",
          color: "yellow accent-4",
          textcolor: "blue-grey darken-4",
          headercolor: "primary",
          order: 3,
          status: 2,
          top_visible: 1,
        },
        {
          type: 12,
          text: "Hold",
          value: "hold",
          description: "Hold Visits",
          activity_text: "Hold",
          color: "orange darken-4",
          textcolor: "white",
          headercolor: "primary",
          order: 4,
          status: 3,
          top_visible: 1,
        },
        {
          type: 12,
          text: "Completed",
          value: "completed",
          description: "Completed Visits",
          activity_text: "Completed",
          color: "green darken-1",
          textcolor: "white",
          headercolor: "primary",
          order: 5,
          status: 4,
          top_visible: 1,
        },
        {
          type: 12,
          text: "Cancelled",
          value: "cancelled",
          description: "Cancelled Visits",
          activity_text: "Cancelled",
          color: "red lighten-1",
          textcolor: "white",
          headercolor: "primary",
          order: 6,
          status: 5,
          top_visible: 1,
        },
      ],
      customerList: [
        {
          id: 1,
          display_name: "Business Thrust Techsoft Pvt Ltd.",
        },
        {
          id: 2,
          display_name: "jeny",
        },
        {
          id: 3,
          display_name: "Ethan Alex",
        },
      ],
      engineerList: [
        {
          id: 1,
          display_name: "John Doe",
        },
        {
          id: 2,
          display_name: "john",
        },
        {
          id: 3,
          display_name: "Mandarin Mandarin",
        },
        {
          id: 4,
          display_name: "Ang peng Siong",
        },
      ],
      filter: null,
      filterList: [
        {
          text: "Quotation",
          value: "quotation",
          color: "blue lighten-1",
        },
        {
          text: "Contract",
          value: "contract",
          color: "orange lighten-1",
        },
        {
          text: "Visit",
          value: "visit",
          color: "green lighten-1",
        },
        {
          text: "Leave",
          value: "leave",
          color: "red lighten-1",
        },
      ],
    };
  },
  methods: {
    setMenuPosition(e) {
      e.preventDefault();
      this.positionX = e.clientX + 50;
      this.positionY = e.clientY;
      this.$nextTick(() => {
        this.eventPopover = true;
      });
    },
    getEvents(eventData) {
      let settingData = this.lodash.find(
        this.settingsData,
        (row) => row.type.toLowerCase() == eventData.type.toLowerCase()
      );
      if (settingData && settingData.value) {
        let stringArr = [];
        this.lodash.forEach(settingData.value, (row) => {
          if (eventData && eventData[row]) {
            stringArr.push(eventData[row]);
          }
        });
        return stringArr.join("/");
      }
    },
    openSingledata(data) {
      if (data.eventParsed.input && data.eventParsed.input.id) {
        if (data.eventParsed.input.type == "visit") {
          this.visitId = toSafeInteger(data.eventParsed.input.id);
          this.viewVisitDialog = true;
        } else if (data.eventParsed.input.type == "contract") {
          this.contractId = toSafeInteger(data.eventParsed.input.id);
          this.viewContractDialog = true;
        } else if (data.eventParsed.input.type == "quotation") {
          this.quotationId = toSafeInteger(data.eventParsed.input.id);
          this.viewQuotationDialog = true;
        } else if (data.eventParsed.input.type == "Leave") {
          this.leaveId = toSafeInteger(data.eventParsed.input.id);
          this.viewLeaveDialog = true;
        }
      }
    },
    getColor(data) {
      if (data == 1) {
        return "blue";
      } else if (data == 2) {
        return "yellow";
      } else if (data == 3) {
        return "red";
      } else if (data == 4) {
        return "green";
      } else if (data == 5) {
        return "red";
      } else if (data == 6) {
        return "cyan";
      }
    },
    getPriorityColor(data) {
      if (data == "Low") {
        return "5px solid green !important";
      } else if (data == "Medium") {
        return "5px solid orange !important";
      } else if (data == "High") {
        return "5px solid red !important";
      }
    },
    getBgColor(data) {
      if (data == 1) {
        return "#E3F2FD  !important";
      } else if (data == 2) {
        return "#f9f9ce !important";
      } else if (data == 3) {
        return "#f3e6cb !important";
      } else if (data == 4) {
        return "#E8F5E9 !important";
      } else if (data == 5) {
        return "#FFEBEE !important;";
      } else if (data == 6) {
        return "#d9fffd !important";
      }
    },
    formatDateTimeStart(row) {
      return moment(row).format("hh:mm A");
    },
    formatetimedata(row) {
      return moment(row).format("hh:mm A");
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    /*  visitFuntion(){
      alert(43533)
    }, */
    showAllEvent({ nativeEvent, event }) {
      this.selectedElement = nativeEvent.target;
      const date = this.selectedElement.getAttribute("data-date");
      const open = () => {
        // this.selectedEvent = event;
        console.log("event", event);
        this.moreElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.moreOpen = true))
        );
      };

      if (this.moreOpen) {
        this.moreOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
      this.getPopupData(date);
      this.getPopupVisit(date);
    },
    getPopupVisit(date) {
      const _this = this;

      _this.$store
        .dispatch(QUERY, {
          url: "popup-calendar",
          data: { start_date: date },
        })
        .then((data) => {
          _this.eventspopup = data;
        });
    },
    updateRange({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        });
      }

      /* this.events = events; */
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    getVisitList(datePicker, type) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "visit-list",
              data: {
                date: datePicker,
                type: type,
                search: this.search,
              },
            })
            .then(({ data }) => {
              this.visitArr = data;
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getCalendar(date) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "all/calendar",
          data: {
            date: date,
            filter: this.filter,
          },
        })
        .then(({ data }) => {
          _this.events = data.row;
          _this.settingsData = data.settings;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getPopupData(date) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "all/calendar",
          data: {
            date: date,
          },
        })
        .then((data) => {
          _this.moreData = data.data.row;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    functionEvents(date) {
      const [, , day] = date.split("-");
      if ([12, 17, 28].includes(parseInt(day, 10))) return true;
      if ([1, 19, 22].includes(parseInt(day, 10))) return ["red", "#00f"];
      return false;
    },
    genrateEvent() {
      const event = [...Array(36)].map(() => {
        const day = Math.floor(Math.random() * 30);
        const d = new Date();
        d.setDate(day);
        return d.toISOString().substr(0, 10);
      });
      return event;
    },
  },
  components: {
    CreateEvent,
    DatePicker,
    SingleVisitDetail,
    SingleQuotationDetail,
    SingleLeaveDetail,
    SingleContractDetail,
  },
  mounted() {
    this.arrayEvents = this.genrateEvent();
    this.getCalendar();
    this.getVisitList();
  },
};
</script>
<style scoped>
.w-100 {
  width: 100px;
}
.all-event-wrapper th,
.all-event-wrapper td {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;
}
.all-event-wrapper th {
  font-weight: 500;
}
</style>
